import React from 'react';
import { graphql } from 'gatsby';
import Layout from "../components/layout";
import SEO from '../components/seo';


const breadcrumb = [{ title: "Home", link: "/" }, { title: "Governance e Compliance" }];

const Governance = ({ data, location }) => {
  const { seo, heroDesktop, heroMobile } = data.contatti;
  return (
    <Layout location={location} hero={{ title: 'Governance e Compliance', className: 'hero--small', breadcrumb, heroDesktop, heroMobile }}>
      <SEO title={`Governance e Compliance`} seo={seo} />
      <section className="section news news--grid">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <article className="article">
                <p>
                  Una buona governance e una buona strategia sono alla base della nostra capacità di mantenere le nostre promesse, servire l'interesse pubblico e rispettare i nostri obblighi secondo i principi etici, le regole, le procedure, le politiche, i bilanci, le certificazioni e tutto ciò che riguarda la gestione e il governo della nostra organizzazione.
                </p>
                <p><strong><a href="https://www.ariadnegroup.it/images/Codice-Etico-del-Gruppo-DGS.pdf" target="_blank" rel="noopener noreferrer">CODICE ETICO</a></strong></p>
                <p>
                  Da sempre, riteniamo di fondamentale importanza il rispetto dei principi etici nella gestione del nostro business.
                </p>
                <p><strong><a href="https://report.whistleb.com/it/dgsspa" target="_blank" rel="noopener noreferrer">WHISTLEBLOWING (segnalazione reati)</a></strong></p>
                <p>
                  Ci impegniamo a mantenere un clima aziendale trasparente e un'alta etica lavorativa, per questo diamo grande valore alla sicurezza e al rispetto di tutte le persone che hanno legami e contatti con noi.
                </p>
                <p><strong><a href="https://www.ariadnegroup.it/images/PL-10-02-Segnalazioni-suggerimenti-reclami-Whistleblowing-v03.pdf" target="_blank" rel="noopener noreferrer">POLITICA PER LA GESTIONE DELLE SEGNALAZIONI</a></strong></p>
                <p>
                  La Politica per la Gestione delle Segnalazioni fornisce chiare indicazioni circa oggetto, contenuti, destinatari e modalità di trasmissione delle segnalazioni che possono essere fatte attraverso il sistema del Whistleblowing, evidenziando altresì le forme di tutela garantite al segnalante, al fine di prevenire ed intercettare eventuali irregolarità e situazioni di non compliance.
                </p>
              </article>
            </div>
          </div>

        </div>
      </section>
    </Layout>
  );
}

export default Governance;
export const query = graphql`{
  contatti: contentfulPaginaGenerica(contentful_id: { eq: "4CTw0M5UUuR5Sg3pbqN8Pf" }) {
    seo {
      ...SeoFragment
    }
    heroDesktop: hero {
      ...HeroContentfulDesktop
    }
    heroMobile: hero {
      ...HeroContentfulMobile
    }
  }
}`;


